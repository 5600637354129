import _ from 'lodash';
import getUsers from 'src/common/api';

// ---  helpers ---

const uniqArray = (a) => a.filter((item, pos) => {
  return a.indexOf(item) === pos;
});

const mergeRegions = (a) => {
  const output = [];
  a.forEach(item => {
    const existing = output.filter(v => {
      return v.url === item.url;
    });
    if (existing.length) {
      const existingIndex = output.indexOf(existing[0]);
      output[existingIndex].region = output[existingIndex].region.concat(item.region);

      if (output[existingIndex].region.length > 1) {
        output[existingIndex].multiRegion = true;
      }
    } else {
      const newItem = {...item};
      if (typeof newItem.region === 'string') {
        newItem.region = [item.region];
      }
      output.push(newItem);
    }
  });
  return output;
};

async function handleWebsitesLoaded(dispatch, data, newWebsitesArray) {
  // extend with thunk
  await dispatch({type: 'WEBSITES_LOADED', newWebsites: newWebsitesArray});
  await dispatch({type: 'METADATA_LOADED', data});

  // set selected website by default
  if (newWebsitesArray.length < 2) {
    const region = (typeof newWebsitesArray[0].region === 'string')
      ? newWebsitesArray[0].region : newWebsitesArray[0].region[0];
    const newSelectedWebsite = {...newWebsitesArray[0], ...{selectedRegion: region}};
    dispatch({type: 'WEBSITE_SELECTED', selectedWebsite: newSelectedWebsite});
  }
}

/*

  Sign user quietly into Firebase

  */

async function getUserMetaData(pairTokens, authUser, dispatch) {
  try {
    const data = await getUsers(authUser.sub, pairTokens.accessToken);
    const {app_metadata: meta} = data;
    const {websiteLease, defaultWebsiteLease, roles} = meta;
    const role = roles.join(' ');
    const regionArray = [];
    let websitesArray = [];
    let websiteLeaseArray = [];

    if (defaultWebsiteLease && (role === 'reseller' || role === 'admin')) {
      websiteLeaseArray = websiteLease.split(',');

      for (let i = 0; i < websiteLeaseArray.length; i++) {
        const decodedUrl = atob(websiteLeaseArray[i]);
        // region exist
        regionArray.push({
          id: i,
          url: decodedUrl.split('#')[0],
          region: decodedUrl.match(/[^#]*$/)[0],
          multiRegion: false
        });
      }
      websitesArray = mergeRegions(uniqArray(regionArray));
    } else {
      const decodedUrl = atob(websiteLease);

      websitesArray.push({
        id: 0,
        url: decodedUrl.split('#')[0],
        region: decodedUrl.match(/[^#]*$/)[0],
        multiRegion: false
      });
    }

    const newWebsitesArray = _.orderBy(websitesArray, ['url'], ['asc']);
    await handleWebsitesLoaded(dispatch, data, newWebsitesArray);
  } catch (e) {
    console.log(e.message);
  }
  return pairTokens;
}

export default getUserMetaData;
export const TEST = { mergeRegions, uniqArray, handleWebsitesLoaded };
