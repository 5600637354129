import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    keyword: 'loading...',
    rankedUrl: 'loading...',
    timestamp: 'loading...',
    currentRanking: '-',
    previousRanking: '-',
    lastMonthRanking: '-',
    googleEngine: 'loading...'
  }
];
