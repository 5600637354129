import firebase from 'firebase';

const dev = String(process.env.REACT_APP_IS_DEV) === 'true';

const config = {
	apiKey: (dev === true) ? process.env.REACT_APP_DEV_API_KEY
	: process.env.REACT_APP_PROD_API_KEY,
	authDomain: (dev === true) ? process.env.REACT_APP_DEV_AUTH_DOMAIN
	: process.env.REACT_APP_PROD_AUTH_DOMAIN,
	databaseURL: (dev === true) ? process.env.REACT_APP_DEV_DATABASE_URL
	: process.env.REACT_APP_PROD_DATABASE_URL,
	projectId: (dev === true) ? process.env.REACT_APP_DEV_PROJECT_ID
	: process.env.REACT_APP_PROD_PROJECT_ID,
	storageBucket: (dev === true) ? process.env.REACT_APP_DEV_STORAGE_BUCKET_ID
	: process.env.REACT_APP_PROD_STORAGE_BUCKET_ID,
	messagingSenderId: (dev === true) ? process.env.REACT_APP_DEV_MESSAGING_SENDER_ID
	: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
	appId: (dev === true) ? process.env.REACT_APP_DEV_APP_ID
	: process.env.REACT_APP_PROD_APP_ID,
	measurementId: (dev === true) ? process.env.REACT_APP_DEV_MEASUREMENT_ID
	: process.env.REACT_APP_PROD_MEASUREMENT_ID
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;
