import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import NotificationRow from 'src/components/NotificationRow';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'arial',
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NotificationView = () => {
  const user = useSelector(state => state.user);
  const notifications = useSelector(state => state.notifications);
  const classes = useStyles();

  // user object is loaded and user role does not equal admin
  if (user.role !== 'admin' && user.id !== null) {
    return <Navigate to="/404" />;
  }

  // user id does not equal null
  if (user.id === null) {
    return <Loading />;
  }

  return (
    <Page
      className={classes.root}
      title="Issues"
      style={{paddingTop: 0}}
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Card style={{padding: 20}}>
            <Grid container justify="flex-start" spacing={0}>
              <Grid item xs={8} sm={8} md={8}>
                <Typography
                  color="textPrimary"
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '20px'
                  }}
                >
                  Issues
                </Typography>
              </Grid>
              {/*
              <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'right' }}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={resolved}
                      onChange={() => {}}
                      name="resolved"
                      color="primary"
                    />
                  )}
                  label="Resolve"
                />
              </Grid>
              */}
            </Grid>
            <Grid container justify="flex-start" spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justify="center" alignItems="center" spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={3} sm={3} md={3} lg={3} style={{ fontSize: 15 }}>
                    <b>Website URL</b>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} style={{ fontSize: 16 }}>
                    <b>Message</b>
                  </Grid>
                </Grid>
                {notifications && notifications.length > 0 && notifications.map((item) => {
                  return <NotificationRow {...item} key={`notification-${uuid()}`} />;
                })}
                {typeof notifications === 'object' && !notifications.length
                  && (
                    <NotificationRow
                      {...{
                      message: 'loading...',
                      region: 'loading...',
                      role: 'loading...',
                      website: 'loading...'
                     }}
                      key={`notification-${uuid()}`}
                    />
                  )}
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default NotificationView;
