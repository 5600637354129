function getUserData(data) {
	const {
		name,
		user_id: id,
		app_metadata: meta
	} = data;
	const {
		roles,
		topResults,
		websiteLease,
		defaultWebsiteLease
	} = meta;
	const role = roles.join(' ');
	return {
		name, id, role, topResults, websiteLease, defaultWebsiteLease
	};
}

const DEFAULT = {
	id: null, name: null, role: null, websiteLease: null, defaultWebsiteLease: null
};

const user = (state = DEFAULT, action) => {
	switch (action.type) {
		case 'METADATA_LOADED': return {...state, ...getUserData(action.data)};
		case 'MERGE_SHOW_INITIAL_RANKING': return {...state, ...action.data};
		default: return state;
	}
};

export default user;
