import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import firebase from '../../common/firebase';
import CONSTANTS from '../../common/constants';
import getUserMetaData from './getUserMetaData';

const { domain, firebaseToken } = CONSTANTS;

const getFirebaseDatabase = () => {
  return firebase.database();
};

const TableViewLayout = (props) => {
  const { app, websites } = props;
  const [loaded, setLoaded] = useState(false);
  const [websiteRef, setWebsiteRef] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const { user: authUser, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // Get user auth0 metadata
  async function getAccessToken() {
    let pairTokens;
    try {
      pairTokens = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: 'read:current_user',
      }).then(accessToken => {
         return axios.post(firebaseToken, {
            token: accessToken
          })
          .then(response => {
            return {
              accessToken,
              customToken: response.data.token
            };
          })
          .catch(error => {
            console.log(error);
          });
      });
    } catch (e) {
      console.log(e.message);
    }
    return pairTokens;
  }

  // Sign user quietly into Firebase
  function signIn(pairTokens) {
    return firebase.auth().signInWithCustomToken(pairTokens.customToken)
    .then(signedInUser => {
      dispatch({ type: 'FIREBASE_USER_SIGNED_IN', firebaseUser: signedInUser });
      return signedInUser;
    })
    .catch((error) => {
      console.log(error);
      return error.message;
    });
  }

  const getNotificatons = () => {
    getFirebaseDatabase().ref('notifications').limitToFirst(25)
    .on('value', (snapshot) => {
      const itemsArray = Object.values(snapshot.val()).map(item => {
        return {
          message: item.message,
          region: item.region,
          role: item.role,
          website: item.website
        };
      });
      dispatch({ type: 'NOTIFICATIONS_LOADED', notifications: itemsArray });
    });
  };

  const getSerps = () => {
    // default settings
    const region = (typeof websites.list[0].region === 'string') ? websites.list[0].region : websites.list[0].region[0];
    let websiteId = btoa(`${websites.list[0].url}#${region}`);

    if (websites.selected) {
        websiteId = Buffer.from(`${websites.selected.url}#${websites.selected.selectedRegion}`).toString('base64');
    }

    setWebsiteRef(websiteId);

    return getFirebaseDatabase().ref(`websiteKeywords/${websiteId}`)
      .once('value').then(snapshot => {
        return Promise.resolve(snapshot.val());
    });
  };

  const getKeywords = () => {
    const data = { showInitialRanking: false };
    return getSerps().then(async (serps) => {
      const itemsArray = Object.values(serps).map(serp => {
        data.showInitialRanking = serp.showInitialRanking;
        return {
          id: serp.id,
          keyword: serp.keyword,
          rankedUrl: serp.rankedUrl,
          timestamp: serp.timestamp,
          initialRanking: serp.initialRanking,
          currentRanking: serp.currentRanking,
          previousRanking: serp.previousRanking,
          lastMonthRanking: serp.lastMonthRanking,
          googleEngine: serp.searchEngine,
          region: serp.region || ''
        };
      });
      await dispatch({ type: 'MERGE_SHOW_INITIAL_RANKING', data });
      return Promise.resolve(itemsArray);
    });
  };

  const getWebStats = () => {
    let websiteUrl = btoa(websites.list[0].url);

    if (websites.selected) {
      websiteUrl = btoa(websites.selected.url);
    }

    getFirebaseDatabase().ref(`websiteStats/${websiteUrl}`)
      .once('value').then(snapshot => {
        dispatch({ type: 'WEBSITE_STATS_LOADED', websiteStats: snapshot.val() });
    });
    return Promise.resolve(true);
  };

  useEffect(() => {
    let isSubscribed = true;
    // Update keywords if website changed
    // NB: Checks domain.com#region !== websiteRef (domain2.com#region2)
    // NB: Check app.firebase is loaded for single websites that require selected by default ***
    // NB: Scope isSubscribed higher
    if (websites.selected
      && Buffer.from(`${websites.selected.url}#${websites.selected.selectedRegion}`).toString('base64') !== websiteRef
      && websiteRef !== false
      && app.firebase && isSubscribed) {
      getKeywords().then(newKeywords => {
        dispatch({ type: 'KEYWORDS_LOADED', newKeywords });
      }).then(() => {
        getWebStats().then(() => {
          if (props.user.role === 'admin') {
            getNotificatons();
          }
        });
      });
      // eslint-disable-next-line
      return () => isSubscribed = false;
    }
    // Initial keyword load
    if (!loaded && app.firebase) {
      setLoaded(true);
      getKeywords().then(newKeywords => {
        dispatch({ type: 'KEYWORDS_LOADED', newKeywords });
      }).then(() => {
        getWebStats().then(() => {
          if (props.user.role === 'admin') {
            getNotificatons();
          }
        });
      });
    }
    // Sign in
    // eslint-disable-next-line
    if (authLoaded) return;
      setAuthLoaded(true);
      getAccessToken().then(pairTokens => {
      return getUserMetaData(pairTokens, authUser, dispatch);
    }).then(pairTokens => {
      return signIn(pairTokens);
    });
    // eslint-disable-next-line
    return () => isSubscribed = false;
  }, [dispatch, app, websites, websiteRef]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

TableViewLayout.propTypes = {
  app: PropTypes.object,
  user: PropTypes.object,
  websites: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    app: state.app,
    user: state.user,
    websites: state.websites
  };
};

export default connect(mapStateToProps, null)(TableViewLayout);
