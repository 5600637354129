import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const dev = String(process.env.REACT_APP_IS_DEV) === 'true';
  const local = String(process.env.REACT_APP_IS_LOCAL) === 'true';
  const wildCardHostUrl = `${window.location.protocol}//${window.location.hostname}`;

  let redirectUrl = dev === true ? 'https://upweb-frontend-hazel.vercel.app'
  : wildCardHostUrl;

  if (local === true) {
    redirectUrl = 'http://localhost:3000';
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUrl}
      audience="https://seodashboard.au.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node.isRequired
};

export default Auth0ProviderWithHistory;
