import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Paper,
  Card,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import TotalWebsites from './TotalWebsites';
import TotalKeywords from './TotalKeywords';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  statBox: {
    background: '#242b54',
    height: 100,
    color: 'white',
    padding: '20px 10px 0 10px',
    textAlign: 'center',
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    boxShadow: 'initial'
  },
  statBox__text: {
    marginBottom: 10,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  statBox__number: {
    fontSize: 27,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
    marginBottom: '5px'
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const websiteStats = useSelector(state => state.websiteStats);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid>
          <NavLink to="/app/keywords">
            <Card style={{ padding: '20px', marginBottom: 25, cursor: 'pointer' }}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={4} sm={4} md={2}>
                  <Paper className={classes.statBox}>
                    <div className={classes.statBox__number}>
                      {(websiteStats && websiteStats.semrush) ? `${websiteStats.semrush}%` : '-'}
                    </div>
                    <div className={classes.statBox__text} title="Onsite SEO">Onsite SEO</div>
                  </Paper>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <Paper className={classes.statBox}>
                    <div className={classes.statBox__number}>
                      {(websiteStats && websiteStats.mozDomainAuthority) || '-'}
                    </div>
                    <div className={classes.statBox__text} title="Domain Authority">Domain Authority</div>
                  </Paper>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <Paper className={classes.statBox}>
                    <div className={classes.statBox__number}>
                      {(websiteStats && websiteStats.ahrefsDomainRating) || '-'}
                    </div>
                    <div className={classes.statBox__text} title="Ahrefs Domain Rating">Ahrefs Domain Rating</div>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Paper className={classes.statBox}>
                    <div className={classes.statBox__number}>
                      {(websiteStats && websiteStats.ahrefsRank) || '-'}
                    </div>
                    <div className={classes.statBox__text} title="Ahrefs Ranking">Ahrefs Ranking</div>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Paper className={classes.statBox}>
                    <div className={classes.statBox__number}>
                      {(websiteStats && websiteStats.ahrefsBacklinks) || '-'}
                    </div>
                    <div className={classes.statBox__text} title="Indexed Backlinks">Indexed Backlinks</div>
                  </Paper>
                </Grid>
              </Grid>
            </Card>
          </NavLink>
        </Grid>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={12}
            xl={9}
            xs={12}
          >
            <TotalWebsites />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            xl={9}
            xs={12}
          >
            <TotalKeywords />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
