import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { createAppStore } from './redux';
import * as serviceWorker from './serviceWorker';
import App from './App';

const store = createAppStore();

window.DEBUG = true;

ReactDOM.render((
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>
), document.getElementById('root'));

serviceWorker.unregister();
