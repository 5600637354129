import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  colors,
  makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import WebIcon from '@material-ui/icons/WebOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalWebsites = ({ className, websites }) => {
  const classes = useStyles();
  const websiteTotal = websites && websites.list ? websites.list.length : 0;

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent style={{ padding: '25px 25px' }}>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h2"
              style={{
                fontWeight: 'bold',
                marginBottom: '5px'
              }}
            >
              {websiteTotal}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              style={{
                fontWeight: 'normal',
                fontSize: '14px'
              }}
            >
              Total Websites
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <WebIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Divider style={{ margin: '12px 0' }} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            component={NavLink}
            to="/app/websites"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            View all
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalWebsites.propTypes = {
  className: PropTypes.string,
  websites: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    websites: state.websites
  };
};

export default connect(mapStateToProps, null)(TotalWebsites);
