const notifications = (state = {
	message: undefined,
	region: undefined,
	role: undefined,
	website: undefined
}, action) => {
	switch (action.type) {
		case 'NOTIFICATIONS_LOADED': return action.notifications;
		default: return state;
	}
};

export default notifications;
