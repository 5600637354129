import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: 45,
    fontSize: 18,
    fontFamily: 'arial',
    textAlign: 'center'
  }
}));

const WebsitesView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { websites } = props;

  useEffect(() => {}, [websites]);

  return (
    <Page
      className={classes.root}
      title="Websites"
      style={{paddingTop: 0}}
    >
      <Container>
        <Box mt={3}>
          <Grid container justify="flex-start" spacing={3}>
            {websites && websites.list && websites.list.length > 0
              && websites.list.map((item) => {
                const region = (typeof item.region === 'string')
                  ? item.region : item.region[0];
                const newItem = {...item, ...{selectedRegion: region}};
                return (
                  <Grid item key={item.id} xs={6} sm={6} md={6}>
                    <NavLink
                      onClick={() => { dispatch({ type: 'WEBSITE_SELECTED', selectedWebsite: newItem }); }}
                      style={{ color: '#263238' }}
                      to="/app/keywords"
                    >
                      <Card className={classes.card} style={{ position: 'relative' }}>
                        {item.url}
                      </Card>
                    </NavLink>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

WebsitesView.propTypes = {
  websites: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    websites: state.websites
  };
};

export default connect(mapStateToProps, null)(WebsitesView);
