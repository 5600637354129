import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';

import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {},
  statBox: {
    background: '#242b54',
    height: 100,
    color: 'white',
    padding: '20px 10px 0 10px',
    textAlign: 'center',
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    boxShadow: 'initial'
  },
  statBox__text: {
    marginBottom: 10,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default'
  },
  statBox__number: {
    fontSize: 27,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
    marginBottom: '5px'
  },
  googleSearch: {
    textTransform: 'capitalize'
  }
}));

const topResults100 = 100;
const topResults200 = 200;

const TableViewOnly = ({ className, ...rest }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const keywords = useSelector(state => state.keywords);
  const websites = useSelector(state => state.websites);
  const comparison = useSelector(state => state.comparison);
  const dispatch = useDispatch();
  const { selected } = websites;
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [userLoaded, setUserLoaded] = useState(false);

  let websiteId = '';

  if (Object.keys(websites).length > 0) {
    if (websites.list !== null) {
      const region = (typeof websites.list[0].region === 'string') ? websites.list[0].region : websites.list[0].region[0];
      websiteId = btoa(`${websites.list[0].url}#${region}`);

      if (websites.selected) {
          websiteId = btoa(`${websites.selected.url}#${websites.selected.selectedRegion}`);
      }
    }
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRegion = (ignore, value) => {
    if (value) {
      const newSelectedModel = selected;
      newSelectedModel.selectedRegion = value.region;
      dispatch({ type: 'WEBSITE_SELECTED', selectedWebsite: newSelectedModel });
    }
  };

  const handleComparison = (event) => {
    const { currentTarget } = event;
    const value = currentTarget.getAttribute('data-graph-icon');
    const newArray = [...comparison];
    const newKeyword = atob(value.split('/')[1]);

    const keywordChecker = newArray.filter(item => atob(item.url.split('/')[1]) === newKeyword);
    if (Object.keys(keywordChecker).length > 0) {
      _.remove(newArray,
        (item) => atob(item.url.split('/')[1]) === newKeyword);
    } else {
      if (newArray.length > 2) return;

      newArray.push({
        url: value
      });
    }

    dispatch({
      type: 'COMPARISON_CHECKED',
      comparison: newArray
    });

    dispatch({
      type: 'KEYWORD_DRAWER',
      openState: true
    });
  };

  const sortByDefault = () => {
    const newModel = [...keywords];
    if (userLoaded) {
      for (let i = 0; i < keywords.length; i++) {
        const {
          currentRanking,
          previousRanking,
          lastMonthRanking,
          initialRanking
        } = keywords[i];
        if (currentRanking === 0) { newModel[i].currentRanking = topResults100; }
        if (previousRanking === 0) { newModel[i].previousRanking = topResults100; }
        if (lastMonthRanking === 0) { newModel[i].lastMonthRanking = topResults100; }
        if (initialRanking === 0) { newModel[i].initialRanking = topResults100; }

        if (currentRanking > topResults100 && user.topResults !== topResults200) {
          newModel[i].currentRanking = topResults100;
        }
        if (previousRanking > topResults100 && user.topResults !== topResults200) {
          newModel[i].previousRanking = topResults100;
        }
        if (lastMonthRanking > topResults100 && user.topResults !== topResults200) {
          newModel[i].lastMonthRanking = topResults100;
        }
        if (initialRanking > topResults100 && user.topResults !== topResults200) {
          newModel[i].initialRanking = topResults100;
        }
      }
    }
    return newModel.sort((a, b) => b.currentRanking - a.currentRanking).reverse();
  };

  const useSortableData = (items, config = {key: '', direction: 'ascending'}) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
      const sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'descending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'descending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig
        && key !== null
        && sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const sortDirection = (sortConfig, key) => {
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return 'asc';
      }
      return 'desc';
    }

    return 'asc';
  };

  const renderRankingLabel = (rankingLabel) => {
    let label = rankingLabel;
    if (rankingLabel === topResults100 && user.topResults !== topResults200) {
      label += '+';
    }
    if (rankingLabel > topResults200 && user.topResults === topResults200) {
      label = '200+';
    }
    return label;
  };

  // region
  const options = [];
  if (selected && selected.multiRegion === true) {
    for (let i = 0; i < selected.region.length; i++) {
      options.push({
        id: i,
        text: `Google ${selected.region[i]}`,
        region: selected.region[i]
      });
    }
  }

  // pagination
  const offset = page * limit;
  const { items, requestSort, sortConfig } = useSortableData(sortByDefault());
  const paginatedItems = items.slice(offset).slice(0, limit);

  useEffect(() => {
    if (user.id) {
      setUserLoaded(true);
    }
  }, [user]);

  return (
    <>
      {websites.selected && websites.selected.multiRegion === true && (
        <Card>
          <Autocomplete
            name="region"
            id="region"
            onChange={handleRegion}
            style={{ float: 'right', padding: '10px 25px', width: 300 }}
            options={options}
            getOptionLabel={(option) => option.text || ''}
            renderInput={(params) => <TextField {...params} label="Select a region" variant="outlined" />}
          />
        </Card>
        )}
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  key="comparison"
                  sortDirection={sortDirection(sortConfig, 'keyword')}
                >
                  <TableSortLabel>
                    &nbsp;
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  key="keyword"
                  style={{minWidth: 250}}
                  sortDirection={sortDirection(sortConfig, 'keyword')}
                >
                  <TableSortLabel
                    active={sortConfig && sortConfig.key === 'keyword'}
                    direction={sortDirection(sortConfig, 'keyword')}
                    onClick={() => requestSort('keyword')}
                  >
                    Keyword
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  key="currentRanking"
                  sortDirection={sortDirection(sortConfig, 'currentRanking')}
                >
                  <TableSortLabel
                    active={sortConfig && sortConfig.key === 'currentRanking'}
                    direction={sortDirection(sortConfig, 'currentRanking')}
                    onClick={() => requestSort('currentRanking')}
                  >
                    Current Ranking
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  onClick={() => requestSort('previousRanking')}
                >
                  <TableSortLabel
                    active={sortConfig && sortConfig.key === 'previousRanking'}
                    direction={sortDirection(sortConfig, 'previousRanking')}
                    onClick={() => requestSort('previousRanking')}
                  >
                    Previous Day
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  onClick={() => requestSort('lastMonthRanking')}
                >
                  <TableSortLabel
                    active={sortConfig && sortConfig.key === 'lastMonthRanking'}
                    direction={sortDirection(sortConfig, 'lastMonthRanking')}
                    onClick={() => requestSort('lastMonthRanking')}
                  >
                    Last Month
                  </TableSortLabel>
                </TableCell>
                {user.showInitialRanking
                  && (
                    <TableCell
                      onClick={() => requestSort('initialRanking')}
                    >
                      <TableSortLabel
                        active={sortConfig && sortConfig.key === 'initialRanking'}
                        direction={sortDirection(sortConfig, 'initialRanking')}
                        onClick={() => requestSort('initialRanking')}
                      >
                        Initial Ranking
                      </TableSortLabel>
                    </TableCell>
                )}
                <TableCell
                  key="rankedUrl"
                  sortDirection={sortDirection(sortConfig, 'rankedUrl')}
                >
                  <TableSortLabel
                    active={sortConfig && sortConfig.key === 'rankedUrl'}
                    direction={sortDirection(sortConfig, 'rankedUrl')}
                    onClick={() => requestSort('rankedUrl')}
                  >
                    Ranked URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Search Engine
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedItems.map(keyword => {
                const {
                  currentRanking,
                  previousRanking,
                  lastMonthRanking,
                  initialRanking
                } = keyword;
                const iconChecker = comparison.filter(item => atob(item.url.split('/')[1]) === keyword.keyword);
                const iconStyle = Object.keys(iconChecker).length > 0
                  ? {cursor: 'pointer', color: '#43a047'} : {cursor: 'pointer'};
                return (
                  <TableRow
                    hover
                    key={keyword.id}
                  >
                    <TableCell>
                      <BarChartOutlinedIcon
                        style={iconStyle}
                        onClick={handleComparison}
                        data-graph-icon={`${websiteId}/${btoa(keyword.keyword)}`}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {keyword.keyword}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {userLoaded && renderRankingLabel(currentRanking)}
                    </TableCell>
                    <TableCell>
                      {userLoaded && renderRankingLabel(previousRanking)}
                    </TableCell>
                    <TableCell>
                      {userLoaded && renderRankingLabel(lastMonthRanking)}
                    </TableCell>
                    {user.showInitialRanking
                      && (
                        <TableCell>
                          {userLoaded && renderRankingLabel(initialRanking)}
                        </TableCell>
                      )}
                    <TableCell>
                      {keyword.rankedUrl}
                    </TableCell>
                    <TableCell>
                      Organic
                    </TableCell>
                    <TableCell>
                      <span className={clsx(classes.googleSearch)}>{keyword.googleEngine}</span>
                      {' '}
                      {keyword.region}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={keywords.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Card>
    </>
  );
};

TableViewOnly.propTypes = {
  className: PropTypes.string
};

export default TableViewOnly;
