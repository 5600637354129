// For creating the app store instance
import { applyMiddleware, createStore, combineReducers } from 'redux';
import DEFAULT_KEYWORDS from 'src/views/keywords/KeywordListView/data';
import middleware from './middleware';
import user from './user';
import websites from './websites';
import notifications from './notifications';
// All top-level reducers get imported here, and exported via the rootReducer

const actionType = (state = null, action) => { // eslint-disable-line no-unused-vars
	return action.type; // TODO: push onto a history array?
};

const app = (state = { dev: null, firebase: null }, action) => {
	switch (action.type) {
		case 'METADATA_LOADED': return {...state, ...{dev: action.data.app_metadata.dev}};
		case 'FIREBASE_USER_SIGNED_IN': return {...state, ...{firebase: action.firebaseUser}};
		default: return state;
	}
};

const keywords = (state = DEFAULT_KEYWORDS, action) => {
	switch (action.type) {
		case 'KEYWORDS_LOADED': return action.newKeywords;
		default: return state;
	}
};

const comparison = (state = [], action) => {
	switch (action.type) {
		case 'COMPARISON_CHECKED': return action.comparison;
		default: return state;
	}
};

const keywordDrawer = (state = false, action) => {
	switch (action.type) {
		case 'KEYWORD_DRAWER': return action.openState;
		default: return state;
	}
};

const websiteStats = (state = {
	ahrefsBacklinks: 0,
	ahrefsDomainRating: 0,
	ahrefsRank: 0,
	ahrefsUrlRank: 0,
	mozDomainAuthority: 0
}, action) => {
	switch (action.type) {
		case 'WEBSITE_STATS_LOADED': return action.websiteStats;
		default: return state;
	}
};

const rootReducer = combineReducers({
	actionType,
	app,
	keywords,
	comparison,
	keywordDrawer,
	websiteStats,
	notifications,
	user,
	websites
});

export default rootReducer;

// Create the store here, to make getting test instances easier
export const createAppStore = () => createStore(rootReducer, applyMiddleware(...middleware));
