import CONSTANTS from './constants';

const { domain } = CONSTANTS;

const getUsers = (sub, accessToken) => {
  const url = `https://${domain}/api/v2/users/${sub}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(response => response.json());
};

export default getUsers;
