import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Search as SearchIcon,
  ArrowUpRight as ArrowUpRightIcon
} from 'react-feather';
import NavItem from './NavItem';

const ItemList = () => {
  const user = useSelector(state => state.user);

  const items = [
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
    {
      href: '/app/websites',
      icon: ArrowUpRightIcon,
      title: 'Websites'
    },
    {
      href: '/app/keywords',
      icon: SearchIcon,
      title: 'Keywords'
    }
  ];

  if (user && user.role === 'admin') {
    items.push({
      href: '/app/issues',
      icon: SearchIcon,
      title: 'Issues'
    });
  }

  useEffect(() => {
    //
  }, [user]);

  return (
    <List>
      {items.map((item) => (
        <NavItem
          href={item.href}
          key={item.title}
          title={item.title}
          icon={item.icon}
        />
      ))}
    </List>
  );
};

export default ItemList;
