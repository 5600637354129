import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundImage: 'url(/static/background.jpg)',
    backgroundSize: 'cover'
  }
}));

const dev = String(process.env.REACT_APP_IS_DEV) === 'true';
const local = String(process.env.REACT_APP_IS_LOCAL) === 'true';
const wildCardHostUrl = `${window.location.protocol}//${window.location.hostname}`;

const pageTitle = dev === true ? 'Login: dev' : 'Login';

let redirectUrl = dev === true ? 'https://upweb-frontend-hazel.vercel.app'
: wildCardHostUrl;

if (local === true) {
  redirectUrl = 'http://localhost:3000';
}

const LoginView = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <Page
      className={classes.root}
      title={pageTitle}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Sign in
              </Typography>
              <Typography
                color="textSecondary"
                style={{ color: '#263238' }}
                gutterBottom
                variant="body2"
              >
                Sign in using Auth0 Authentication
              </Typography>
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => loginWithRedirect({ redirect_uri: redirectUrl })}
              >
                Sign in now
              </Button>
            </Box>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
