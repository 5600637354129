import React from 'react';

const loadingImg = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const Loading = () => (
  <div style={{
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}}>
    <img
      src={loadingImg}
      style={{
        width: 120,
        height: 120
      }}
      alt="Loading..."
    />
  </div>
);

export default Loading;
