import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { Clear, ArrowBack } from '@material-ui/icons';
import { Line } from 'react-chartjs-2';

const useStyles = makeStyles(() => ({
  root: {
    width: 400
  },
  content: {
    padding: 20,
    fontFamily: 'arial'
  },
  header: {
    fontSize: 18,
    marginTop: 2,
    float: 'left'
  },
  subHeader: {
    fontSize: 18,
    float: 'left',
    paddingBottom: 2,
    marginBottom: 7,
    borderBottom: '2px solid'
  },
  para: {
    fontSize: 14,
    lineHeight: '18px',
    clear: 'both'
  },
  keywordItem: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: '1px solid #e3e3e3'
  },
  keywordItemLast: {
    border: 0
  },
  clear: {
    color: 'red',
    position: 'relative',
    top: -2,
    width: 20,
    height: 20,
    float: 'right',
    cursor: 'pointer'
  },
  arrowBack: {
    color: '#000',
    position: 'relative',
    top: 0,
    width: 20,
    height: 20,
    marginRight: 7,
    float: 'left',
    cursor: 'pointer'
  },
  select: {
    marginRight: 10,
    float: 'right'
  },
  status: {
    position: 'relative',
    margin: '-2px 0 0 4px',
    width: 23,
    height: 23,
    fontSize: 11,
    fontWeight: 'bold',
    background: '#43a047',
    borderRadius: '50%',
    float: 'left',
    textAlign: 'center',
    color: '#fff'
  },
  statusText: {
    position: 'relative',
    top: 5
  },
  mr10: {
    marginRight: 10
  }
}));

const colors = [
  {
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)'
  },
  {
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)'
  },
  {
    borderColor: 'rgb(67, 197, 158)',
    backgroundColor: 'rgba(67, 197, 158, 0.5)'
  }
];

const KeywordComparison = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dateSelected, setDateSelected] = useState(undefined);
  const [keywordsGraphList, setKeywordsGraphList] = useState([]);
  const keywords = useSelector(state => state.keywords);
  const websites = useSelector(state => state.websites);
  const comparison = useSelector(state => state.comparison);
  const isOpen = useSelector(state => state.keywordDrawer);
  const defaultDate = dateSelected || 7;

  let websiteId;

  if (Object.keys(websites).length > 0) {
    if (websites.list !== null) {
      const region = (typeof websites.list[0].region === 'string') ? websites.list[0].region : websites.list[0].region[0];
      websiteId = btoa(`${websites.list[0].url}#${region}`);

      if (websites.selected) {
          websiteId = btoa(`${websites.selected.url}#${websites.selected.selectedRegion}`);
      }
    }
  }

  const getLabels = () => {
    const newArray = [];
    let newDate = Number(defaultDate);

    if (newDate === 90) {
      newDate = moment(moment().subtract(3, 'months')
        .format('YYYY-MM-DD'), 'YYYY-MM-DD');
    }

    if (comparison.length > 0) {
      for (let i = 0; i < keywordsGraphList.length; i++) {
        for (let j = 0; j < keywordsGraphList[i].data[0].length; j++) {
          newArray.push(moment(keywordsGraphList[i].data[0][j].date).format('MMM Do'));
        }
      }
    }

    return newArray.slice(0, newDate).reverse();
  };

  const getDataSets = () => {
    const newArray = [];
    let newDate = Number(defaultDate);

    if (newDate === 90) {
      newDate = moment(moment().subtract(3, 'months')
        .format('YYYY-MM-DD'), 'YYYY-MM-DD');
    }

    if (comparison.length > 0) {
      for (let i = 0; i < keywordsGraphList.length; i++) {
        newArray.push({
          label: `${keywordsGraphList[i].keyword}`,
          data: [],
          borderColor: colors[i].borderColor,
          backgroundColor: colors[i].backgroundColor,
        });

        for (let j = 0; j < keywordsGraphList[i].data[0].length; j++) {
          if (j < newDate) {
            const adjustRanking = keywordsGraphList[i].data[0][j].ranking > 0
              ? keywordsGraphList[i].data[0][j].ranking : 100;
            newArray[i].data.push(
              adjustRanking
            );
          }
        }
        newArray[i].data.reverse();
      }
    }

    return newArray;
  };

  const getChartData = () => {
    return {
      labels: getLabels(),
      datasets: getDataSets(),
    };
  };

  const getCurrentRanking = (keyword) => {
    const item = _.find(keywords, {keyword});
    return item.currentRanking;
  };

  const getRankUrl = (keyword) => {
    const item = _.find(keywords, {keyword});
    return item.rankedUrl;
  };

  const reverseObject = (obj) => {
    const newArray = [];
    Object.keys(obj)
      .sort()
      .reverse()
      .forEach(key => {
        newArray.push({
          id: obj[key].id,
          date: key,
          ranking: obj[key].ranking
        });
      });
    return newArray;
  };

  const handleFetchKeywords = async () => {
    const newArray = [];
    const dev = String(process.env.REACT_APP_IS_DEV) === 'true';
    const path = dev === true ? 'https://ronrbqp8cg.execute-api.ap-southeast-2.amazonaws.com/dev'
      : 'https://tcttcog89k.execute-api.ap-southeast-2.amazonaws.com/prod';
    for (let i = 0; i < comparison.length; i++) {
      const url = `${path}/{read+}`;
      const newKeyword = atob(comparison[i].url.split('/')[1]);
      const item = Promise.all([
        axios.get(`${url}?keywords=${websiteId}/${btoa(newKeyword)}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          crossdomain: true
        })
        .then(response => {
          const newResponse = reverseObject(response.data.response[0]);
          return {
            keyword: newKeyword,
            data: [newResponse]
          };
        })
        .catch(error => {
          console.log(error);
        })
      ]);
      newArray.push(item);
    }
    await Promise.all(newArray).then((values) => {
      const mergeArray = [];
      for (let j = 0; j < values.length; j++) {
        mergeArray.push(values[j][0]);
      }
      setKeywordsGraphList(mergeArray);
    });
  };

  const handleClose = () => {
    dispatch({ type: 'KEYWORD_DRAWER', openState: false });
  };

  const handleDates = (event) => {
    const { target } = event;
    const { value } = target;

    setDateSelected(value);
  };

  const handleClearKeyword = (event) => {
    const { currentTarget } = event;
    const keyword = currentTarget.getAttribute('data-keyword');
    const newKeywordsGraphList = [...keywordsGraphList];
    const newArray = [...comparison];
    const keywordChecker = newArray.filter(item => atob(item.url.split('/')[1]) === keyword);

    if (Object.keys(keywordChecker).length > 0) {
      _.remove(newArray,
        (item) => atob(item.url.split('/')[1]) === keyword);
    }
    _.remove(newKeywordsGraphList,
      (item) => item.keyword === keyword);

    setKeywordsGraphList(newKeywordsGraphList);

    dispatch({
      type: 'COMPARISON_CHECKED',
      comparison: newArray
    });
  };

  const renderKeywords = () => {
    if (comparison.length < 1) { return null; }

    return comparison.map((item, index) => {
      const lastChild = index === comparison.length - 1 ? classes.keywordItemLast : '';
      const wrapper = `${classes.keywordItem} ${lastChild}`;
      const newKeyword = atob(item.url.split('/')[1]);

      return (
        <div key={btoa(newKeyword)} className={wrapper}>
          <h2 className={classes.subHeader} style={{borderColor: colors[index].borderColor}}>
            {newKeyword}
          </h2>
          <div className={classes.status}>
            <div className={classes.statusText}>{getCurrentRanking(newKeyword)}</div>
          </div>
          <Clear
            className={classes.clear}
            data-keyword={newKeyword}
            onClick={handleClearKeyword}
          />
          <p className={classes.para}>
            Ranked url:&nbsp;
            {getRankUrl(newKeyword) || 'No ranking'}
          </p>
        </div>
      );
    });
  };

  const renderChart = () => {
    const options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: false,
            min: 0,
            stepSize: 1,
            callback: (value) => {
              return `${value}`;
            }
          }
        }]
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    };
    const chartData = getChartData();
    return (<Line options={options} data={chartData} />);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (comparison.length > 0 && isSubscribed) {
      handleFetchKeywords();
    }
    // eslint-disable-next-line
    return () => isSubscribed = false;
  }, [comparison]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      hideBackdrop={false}
      onClose={handleClose}
    >
      <div className={classes.root}>
        <div className={classes.content}>
          <h1 className={classes.header}>
            <ArrowBack
              className={classes.arrowBack}
              onClick={handleClose}
            />
            <span>Keyword comparison</span>
          </h1>
          <Select label="Keywords" onChange={handleDates} value={defaultDate} className={classes.select}>
            <MenuItem value="7">Last 7 days</MenuItem>
            <MenuItem value="30">Last 30 days</MenuItem>
            <MenuItem value="90">Last 3 months</MenuItem>
          </Select>
          {renderChart()}
          <br />
          {renderKeywords()}
        </div>
      </div>
    </Drawer>
  );
};

export default KeywordComparison;
