import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const WebsiteSelect = ({ onChange }) => {
  const [updateTextField, setUpdateTextField] = useState(undefined);
  const website = useSelector(state => {
    const { user, websites } = state;
    const { websiteLease, defaultWebsiteLease, role } = user;
    const { list } = websites;

    /*

    If user has a reseller role

    */

    if (defaultWebsiteLease && (role === 'reseller' || role === 'admin')) {
      const websiteSelectorList = [];

      for (let i = 0; i < list.length; i++) {
        websiteSelectorList.push({
          id: list[i].id,
          multiRegion: list[i].multiRegion,
          region: list[i].region,
          selectedRegion: list[i].region[0],
          url: list[i].url
        });
      }

      return websiteSelectorList;
    }

    // Default settings
    const decodedUrl = atob(websiteLease);

    return websiteLease !== null
      ? [{ id: 0, url: decodedUrl.split('#')[0] }]
      : [{ id: 0, url: 'loading' }];
  });
  const dispatch = useDispatch();
  const defaultTextFieldValue = updateTextField || website[0];
  const handleChange = (ignore, value) => {
    setUpdateTextField(value);
    onChange(value);
    dispatch({
      type: 'COMPARISON_CHECKED',
      comparison: []
    });
  };
  return (
    <Autocomplete
      fullWidth
      name="website"
      id="website-select"
      disableClearable
      onChange={handleChange}
      value={defaultTextFieldValue}
      options={website}
      getOptionLabel={(option) => option.url || ''}
      renderInput={(params) => <TextField {...params} label="Select Website" variant="outlined" />}
    />
  );
};

WebsiteSelect.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default WebsiteSelect;
