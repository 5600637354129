import React from 'react';
import { Navigate } from 'react-router-dom';
import TableViewLayout from 'src/layouts/DashboardLayout/TableViewLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import NotificationsView from 'src/views/notifications/NotificationsView';
import KeywordListView from 'src/views/keywords/KeywordListView';
import TableViewOnly from 'src/views/keywords/KeywordListView/TableViewOnly';
import WebsitesView from 'src/views/websites/WebsitesView/index';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

const protectRoutes = (component, isAuthenticated) => {
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }
  return component;
};

const routes = (isAuthenticated) => {
  const defaultRoutes = [
    {
      path: 'app',
      element: protectRoutes(<DashboardLayout />, isAuthenticated),
      children: [
        { path: 'dashboard', element: <DashboardView /> },
        { path: 'account', element: <AccountView /> },
        { path: 'keywords', element: <KeywordListView /> },
        { path: 'websites', element: <WebsitesView /> },
        { path: 'issues', element: <NotificationsView /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'table',
      element: protectRoutes(<TableViewLayout />, isAuthenticated),
      children: [
        { path: 'test1', element: <TableViewOnly /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/signin', element: <LoginView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];

  return defaultRoutes;
};

export default routes;
