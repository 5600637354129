import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import firebase from '../../common/firebase';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const dev = String(process.env.REACT_APP_IS_DEV) === 'true';
const local = String(process.env.REACT_APP_IS_LOCAL) === 'true';
const wildCardHostUrl = `${window.location.protocol}//${window.location.hostname}`;

let redirectUrl = dev === true ? 'https://upweb-frontend-hazel.vercel.app'
: wildCardHostUrl;

if (local === true) {
  redirectUrl = 'http://localhost:3000';
}

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { logout } = useAuth0();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box flexGrow={1} />
        <IconButton
          color="inherit"
          onClick={() => {
            firebase.auth().signOut();
            return logout({ returnTo: redirectUrl });
          }}
        >
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
