import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card
} from '@material-ui/core';

const NotificationRow = (props) => {
  const {
    message,
    website
  } = props;
  return (
    <Grid style={{ marginBottom: 15 }}>
      <Card style={{ padding: '10px 20px' }}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ fontSize: 15 }}>
            {website}
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} style={{ fontSize: 15 }}>
            {message}
          </Grid>
          {/*
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Button color="primary">Resolve</Button>
          </Grid>
          */}
        </Grid>
      </Card>
    </Grid>
  );
 };

 NotificationRow.propTypes = {
  message: PropTypes.string,
  website: PropTypes.string
};

 export default NotificationRow;
